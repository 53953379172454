// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const aiKnowledge: Topic = {
  topic: 'AIKnowledge',
  level: 'Mixed',
  totalQuestions: 21,
  totalScore: 500,
  totalTime: 600,
  roles: {
    Unaware: 0,
    Aware: 0,
    Engaged: 0,
    Practitioner: 0,
    Advocate: 0,
    Leader: 0,
    Innovator: 0,
  },
  questions: [
    {
      question: 'Have you ever participated in an AI-related project?',
      choices: [
        'No',
        "I'm aware of such projects in my organization",
        'Yes, as an observer or minor contributor',
        'Yes, as a key contributor',
        'Yes, leading a component of the project',
        'Yes, as the project leader',
        'Yes, initiating and leading multiple projects',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        "I'm aware of such projects in my organization": 'Aware',
        'Yes, as an observer or minor contributor': 'Engaged',
        'Yes, as a key contributor': 'Practitioner',
        'Yes, leading a component of the project': 'Advocate',
        'Yes, as the project leader': 'Leader',
        'Yes, initiating and leading multiple projects': 'Innovator',
      },
    },
    {
      question: 'How do you rate your understanding of AI technologies?',
      choices: [
        'None',
        'Basic awareness',
        'Basic understanding',
        'Good, with practical application experience',
        'Very good, with frequent application',
        'Expert, with deep understanding and broad application',
        'Leading expert, contributing to the field',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        None: 'Unaware',
        'Basic awareness': 'Aware',
        'Basic understanding': 'Engaged',
        'Good, with practical application experience': 'Practitioner',
        'Very good, with frequent application': 'Advocate',
        'Expert, with deep understanding and broad application': 'Leader',
        'Leading expert, contributing to the field': 'Innovator',
      },
    },
    // Questions 3 to 7 for the AI Knowledge topic
    {
      question: 'How often do you use AI in your daily tasks?',
      choices: [
        'Never',
        'Rarely',
        'Occasionally for specific tasks',
        'Regularly, in many tasks',
        'Frequently, always looking for AI solutions',
        'Always, in all tasks and decisions',
        'I define tasks around AI capabilities',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        Never: 'Unaware',
        Rarely: 'Aware',
        'Occasionally for specific tasks': 'Engaged',
        'Regularly, in many tasks': 'Practitioner',
        'Frequently, always looking for AI solutions': 'Advocate',
        'Always, in all tasks and decisions': 'Leader',
        'I define tasks around AI capabilities': 'Innovator',
      },
    },
    {
      question: 'Can you identify AI technologies in products or services you use?',
      choices: [
        'No',
        'Yes, but I rarely notice',
        'Yes, sometimes I recognize them',
        'Yes, I often recognize and understand their use',
        'Yes, and I evaluate their effectiveness',
        'Yes, and I provide feedback for improvement',
        'Yes, and I suggest new applications or improvements',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        'Yes, but I rarely notice': 'Aware',
        'Yes, sometimes I recognize them': 'Engaged',
        'Yes, I often recognize and understand their use': 'Practitioner',
        'Yes, and I evaluate their effectiveness': 'Advocate',
        'Yes, and I provide feedback for improvement': 'Leader',
        'Yes, and I suggest new applications or improvements': 'Innovator',
      },
    },
    {
      question: 'Have you contributed to the development or improvement of an AI system?',
      choices: [
        'No',
        'Yes, by providing data or feedback',
        'Yes, by designing or testing components',
        'Yes, by leading a development team',
        'Yes, by pioneering new AI technologies',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        'Yes, by providing data or feedback': 'Practitioner',
        'Yes, by designing or testing components': 'Advocate',
        'Yes, by leading a development team': 'Leader',
        'Yes, by pioneering new AI technologies': 'Innovator',
      },
    },
    {
      question: 'Do you stay updated on AI trends and advancements?',
      choices: [
        'No',
        'Rarely, when I come across them',
        'Yes, through popular media',
        'Yes, through professional resources',
        'Yes, through conferences and professional networks',
        'Yes, by participating in think tanks and strategy sessions',
        'Yes, by contributing to research and thought leadership',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        'Rarely, when I come across them': 'Aware',
        'Yes, through popular media': 'Engaged',
        'Yes, through professional resources': 'Practitioner',
        'Yes, through conferences and professional networks': 'Advocate',
        'Yes, by participating in think tanks and strategy sessions': 'Leader',
        'Yes, by contributing to research and thought leadership': 'Innovator',
      },
    },
    {
      question: 'How comfortable are you explaining AI concepts to others?',
      choices: [
        'Not at all',
        'To a small extent, basic concepts only',
        'Somewhat comfortable, with familiar concepts',
        'Comfortable with most concepts',
        'Very comfortable, including potential applications',
        'Extremely comfortable, can explain complex applications',
        'I can elucidate cutting-edge AI concepts and their implications',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not at all': 'Unaware',
        'To a small extent, basic concepts only': 'Aware',
        'Somewhat comfortable, with familiar concepts': 'Engaged',
        'Comfortable with most concepts': 'Practitioner',
        'Very comfortable, including potential applications': 'Advocate',
        'Extremely comfortable, can explain complex applications': 'Leader',
        'I can elucidate cutting-edge': 'Innovator',
      },
    },
    {
      question: 'How involved are you in integrating AI into business strategies?',
      choices: [
        'Not involved',
        'Aware of discussions',
        'Occasionally consulted',
        'Regularly involved in specific aspects',
        'Actively engaged in planning and execution',
        'Leading strategy development and implementation',
        'Setting the vision and ensuring alignment with future technologies',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        'Aware of discussions': 'Aware',
        'Occasionally consulted': 'Engaged',
        'Regularly involved in specific aspects': 'Practitioner',
        'Actively engaged in planning and execution': 'Advocate',
        'Leading strategy development and implementation': 'Leader',
        'Setting the vision and ensuring alignment with future technologies': 'Innovator',
      },
    },
    {
      question: 'Have you taken any formal AI education or training courses?',
      choices: [
        'No',
        'Planned or interested',
        'Yes, introductory courses',
        'Yes, intermediate courses',
        'Yes, advanced courses or certifications',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        'Planned or interested': 'Aware',
        'Yes, introductory courses': 'Engaged',
        'Yes, intermediate courses': 'Practitioner',
        'Yes, advanced courses or certifications': 'Advocate',
        // No entries for Leader and Innovator as specified
      },
    },
    {
      question: 'Do you mentor or guide others in your organization on AI?',
      choices: [
        'No',
        'Yes, informally',
        'Yes, as part of a formal program',
        'Yes, leading internal training initiatives',
        'Yes, as a recognized thought leader and educator',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        // No entries for Aware and Engaged as specified
        'Yes, informally': 'Practitioner',
        'Yes, as part of a formal program': 'Advocate',
        'Yes, leading internal training initiatives': 'Leader',
        'Yes, as a recognized thought leader and educator': 'Innovator',
      },
    },
    {
      question: 'How do you approach ethical considerations in AI projects?',
      choices: [
        'Aware of ethical considerations',
        'Follow guidelines provided',
        'Actively discuss and apply ethical guidelines',
        'Champion ethical AI use in projects',
        'Lead the creation of ethical AI guidelines',
        'Pioneer industry standards for ethical AI',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        // No entry for Unaware as specified
        'Aware of ethical considerations': 'Aware',
        'Follow guidelines provided': 'Engaged',
        'Actively discuss and apply ethical guidelines': 'Practitioner',
        'Champion ethical AI use in projects': 'Advocate',
        'Lead the creation of ethical AI guidelines': 'Leader',
        'Pioneer industry standards for ethical AI': 'Innovator',
      },
    },
    {
      question: 'Are you involved in selecting AI technologies for your organization?',
      choices: [
        'No',
        'Occasionally give input',
        'Regularly assess technologies for fit',
        'Advocate for certain technologies based on performance',
        'Make final decisions on AI technology adoption',
        'Set strategic direction for technology exploration and adoption',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        // No entry for Aware as specified
        'Occasionally give input': 'Engaged',
        'Regularly assess technologies for fit': 'Practitioner',
        'Advocate for certain technologies based on performance': 'Advocate',
        'Make final decisions on AI technology adoption': 'Leader',
        'Set strategic direction for technology exploration and adoption': 'Innovator',
      },
    },
    {
      question: 'How do you perceive the impact of AI on your industry?',
      choices: [
        'No impact',
        'Minor impact, mostly hype',
        'Moderate impact, changing some aspects of work',
        'Significant impact, transforming practices',
        'Major impact, a key driver of industry evolution',
        'Critical impact, redefining industry boundaries',
        'Transformative impact, leading the creation of new industry paradigms',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'No impact': 'Unaware',
        'Minor impact, mostly hype': 'Aware',
        'Moderate impact, changing some aspects of work': 'Engaged',
        'Significant impact, transforming practices': 'Practitioner',
        'Major impact, a key driver of industry evolution': 'Advocate',
        'Critical impact, redefining industry boundaries': 'Leader',
        'Transformative impact, leading the creation of new industry paradigms':
          'Innovator',
      },
    },
    {
      question:
        'Have you participated in cross-functional teams to integrate AI into various business processes?',
      choices: [
        'No',
        'Yes, as a team member',
        'Yes, providing technical expertise',
        'Yes, leading AI integration efforts',
        'Yes, as a senior leader overseeing integration',
        'Yes, defining the vision for AI across the organization',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        No: 'Unaware',
        // No entry for Aware as specified
        'Yes, as a team member': 'Engaged',
        'Yes, providing technical expertise': 'Practitioner',
        'Yes, leading AI integration efforts': 'Advocate',
        'Yes, as a senior leader overseeing integration': 'Leader',
        'Yes, defining the vision for AI across the organization': 'Innovator',
      },
    },
    {
      question: 'How do you contribute to scaling AI solutions within your organization?',
      choices: [
        'Not involved',
        'Provide feedback on scalability',
        'Contribute to technical solutions for scaling',
        'Drive initiatives for scalable AI adoption',
        'Lead strategic planning for AI scalability',
        'Innovate new approaches for large-scale AI deployment',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        // No entry for Aware as specified
        'Provide feedback on scalability': 'Engaged',
        'Contribute to technical solutions for scaling': 'Practitioner',
        'Drive initiatives for scalable AI adoption': 'Advocate',
        'Lead strategic planning for AI scalability': 'Leader',
        'Innovate new approaches for large-scale AI deployment': 'Innovator',
      },
    },
    {
      question: 'How involved are you in data governance related to AI?',
      choices: [
        'Not involved',
        'Aware of its importance',
        'Involved in discussions or training',
        'Contribute to data governance policies',
        'Advocate for strong data governance practices',
        'Lead data governance strategy',
        'Pioneer new standards or models for AI data governance',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        'Aware of its importance': 'Aware',
        'Involved in discussions or training': 'Engaged',
        'Contribute to data governance policies': 'Practitioner',
        'Advocate for strong data governance practices': 'Advocate',
        'Lead data governance strategy': 'Leader',
        'Pioneer new standards or models for AI data governance': 'Innovator',
      },
    },
    {
      question: 'How do you evaluate the success of AI projects in your organization?',
      choices: [
        'Not involved',
        'Participate in debriefs or feedback sessions',
        'Directly involved in measuring project outcomes',
        'Advocate for metrics and KPIs to evaluate AI',
        'Define success criteria and evaluation frameworks',
        'Lead industry in defining AI project success metrics',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        // No entry for Aware as specified
        'Participate in debriefs or feedback sessions': 'Engaged',
        'Directly involved in measuring project outcomes': 'Practitioner',
        'Advocate for metrics and KPIs to evaluate AI': 'Advocate',
        'Define success criteria and evaluation frameworks': 'Leader',
        'Lead industry in defining AI project success metrics': 'Innovator',
      },
    },
    {
      question: 'How do you ensure AI solutions are user-friendly and meet user needs?',
      choices: [
        'Not involved',
        'Provide user feedback when solicited',
        'Involved in user testing and feedback collection',
        'Champion user-centric design in AI solutions',
        'Oversee user experience design for AI projects',
        'Innovate in AI usability and human-centered design',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        // No entry for Aware as specified
        'Provide user feedback when solicited': 'Engaged',
        'Involved in user testing and feedback collection': 'Practitioner',
        'Champion user-centric design in AI solutions': 'Advocate',
        'Oversee user experience design for AI projects': 'Leader',
        'Innovate in AI usability and human-centered design': 'Innovator',
      },
    },
    {
      question: 'How do you foster an AI-literate culture within your organization?',
      choices: [
        'Not involved',
        'Share AI-related news or resources',
        'Conduct informal AI learning sessions',
        'Lead formal training or literacy programs',
        'Develop strategic initiatives for AI education',
        'Establish a center of excellence for AI learning and innovation',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        // No entry for Aware as specified
        'Share AI-related news or resources': 'Engaged',
        'Conduct informal AI learning sessions': 'Practitioner',
        'Lead formal training or literacy programs': 'Advocate',
        'Develop strategic initiatives for AI education': 'Leader',
        'Establish a center of excellence for AI learning and innovation': 'Innovator',
      },
    },
    {
      question: 'How do you address the challenge of AI bias and fairness?',
      choices: [
        'Not aware of the issue',
        'Aware but not involved',
        'Participate in training or discussions',
        'Implement checks for bias in projects',
        'Advocate for practices that ensure fairness',
        'Lead initiatives to mitigate AI bias',
        'Pioneer research or methods to address AI bias and fairness',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not aware of the issue': 'Unaware',
        'Aware but not involved': 'Aware',
        'Participate in training or discussions': 'Engaged',
        'Implement checks for bias in projects': 'Practitioner',
        'Advocate for practices that ensure fairness': 'Advocate',
        'Lead initiatives to mitigate AI bias': 'Leader',
        'Pioneer research or methods to address AI bias and fairness': 'Innovator',
      },
    },
    {
      question:
        'How do you stay abreast of regulatory changes affecting AI in your industry?',
      choices: [
        'Not involved',
        'Aware of major changes',
        'Keep informed through industry news',
        'Participate in compliance and regulatory training',
        'Advocate for regulatory compliance in AI projects',
        'Lead the adaptation to regulatory changes',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Not involved': 'Unaware',
        'Aware of major changes': 'Aware',
        'Keep informed through industry news': 'Engaged',
        'Participate in compliance and regulatory training': 'Practitioner',
        'Advocate for regulatory compliance in AI projects': 'Advocate',
        'Lead the adaptation to regulatory changes': 'Leader',
        // No entry for Innovator as this aspect does not explicitly map to innovation but to compliance and awareness.
      },
    },
  ],
}
