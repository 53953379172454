import { FC } from 'react'
import styled from 'styled-components'

import { useQuiz } from '../../../context/QuizContext'
import { device } from '../../../styles/BreakPoints'
import { HighlightedText } from '../../../styles/Global'
import { convertSeconds } from '../../../utils/helpers'
import { Result } from '../../../types'
import DataConsumer from '../../../assets/icons/keytrade/_Data Consumer-02.svg'
import DataAnalyst from '../../../assets/icons/keytrade/_Data Analyst.svg'
import DataPowerUser from '../../../assets/icons/keytrade/_Data Power user.svg'
import DataExpert from '../../../assets/icons/keytrade/_Data Expert.svg'
import DataOwner from '../../../assets/icons/keytrade/_Data Owner.svg'
import DataSteward from '../../../assets/icons/keytrade/_Data Steward.svg'
import DataCustodian from '../../../assets/icons/keytrade/_Data Custodian.svg'
import RoleBar from './roleBar'

const roleDetails: Record<string, { description: string; pictureURL: string }> = {
  Unaware: {
    description:
      "You're still using a fax machine while Belgium's making AI waffles. Catch up, buddy!",
    pictureURL: DataConsumer, // Placeholder, replace as needed
  },
  Aware: {
    description:
      "You've heard about AI like that one person who knows about a new TV show but hasn't watched it yet. Belgium's already binge-watching AI series!",
    pictureURL: DataAnalyst, // Placeholder, replace as needed
  },
  Engaged: {
    description:
      "You've dipped your toes into the AI pool, but remember, sharks swim in these waters. Dive in, Belgium's already surfing the AI waves!",
    pictureURL: DataPowerUser, // Placeholder, replace as needed
  },
  Practitioner: {
    description:
      "You're like the AI's sidekick, Robin to Batman. Time to put on the cape and become the hero of AI! Belgium's already wearing the cape and fighting crime with AI!",
    pictureURL: DataExpert, // Placeholder, replace as needed
  },
  Advocate: {
    description:
      "You're the AI cheerleader, but we need more than pom-poms to win this game. Time to bring out the AI megaphone! Belgium's already leading the AI pep rally!",
    pictureURL: DataOwner, // Placeholder, replace as needed
  },
  Leader: {
    description:
      "You're the AI trendsetter, but remember, even leaders have to keep up with the Kardashians... I mean, with Belgium's AI standards! Belgium's setting the AI bar high, time to reach for the Belgian chocolate-covered AI stars!",
    pictureURL: DataSteward, // Placeholder, replace as needed
  },
  Innovator: {
    description:
      "Congratulations! You've reached the AI summit, but remember, the air's thin up here. Don't forget your oxygen mask... or your sense of humor! Belgium's already orbiting in the AI galaxy, but you're not far behind!",
    pictureURL: DataCustodian, // Placeholder, replace as needed
  },
}

const ResultOverviewStyle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  @media ${device.md} {
    margin-bottom: 30px;
  }
  img {
    display: block; /* Ensure the image is treated as a block element */
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Ensure the image does not exceed its container width */
  }
  p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 18px;
  }
  h1 {
    margin-bottom: 20px;
  }
`

interface ResultOverviewProps {
  result: Result[]
  roleScores: Record<string, number>
}

const ResultOverview: FC<ResultOverviewProps> = ({ result, roleScores }) => {
  const { quizDetails, endTime } = useQuiz()

  const totalQuestionAttempted = result.length

  const obtainedScore = result
    .filter((item) => item.isMatch && typeof item.score === 'number')
    .reduce((accumulator, currentValue) => accumulator + (currentValue.score || 0), 0)

  // Passed if 60 or more than 60% marks
  const calculateStatus =
    (obtainedScore / quizDetails.totalScore) * 100 >= 60 ? 'Passed' : 'Failed'

  // Calculate the role with the highest score
  const highestScoreRole = Object.keys(roleScores).reduce((a, b) =>
    roleScores[a] > roleScores[b] ? a : b
  )

  const { description, pictureURL } = roleDetails[highestScoreRole]

  return (
    <ResultOverviewStyle>
      <h1>{highestScoreRole}</h1>
      <RoleBar currentRole={highestScoreRole}></RoleBar>
      <p>
        Congratulations, the role which is closest to your preference is:{' '}
        <HighlightedText>{highestScoreRole}</HighlightedText>
      </p>
      <p>{description}</p>
    </ResultOverviewStyle>
  )
}

export default ResultOverview
