import { ReactNode } from 'react'
import { ReactComponent as ReactIcon } from '../assets/icons/react.svg'
import { ReactComponent as DataIcon } from '../assets/icons/keytrade/DataAcadamy.svg'

type QuizTopic = {
  title: string
  icon: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'AI Maturity Test',
    icon: <DataIcon width={140} height={80} />,
    disabled: false,
  },
]

/*
  {
    title: 'Python',
    icon: <DataIcon width={140} height={80} />,
    disabled: false,
  },
{
    title: 'Kotlin',
    icon: <Kotlin />,
    disabled: true,
  },
  {
    title: 'Laravel',
    icon: <Laravel />,
    disabled: true,
  },
*/
