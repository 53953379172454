import { generalKnowledge } from './generalKnowledge'
import { javascript } from './javascript'
import { python } from './python'
import { react } from './react'
import { dataRole } from './dataRole'
import { aiKnowledge } from './aiKnowledge'

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
  answerRoles?: Record<string, string>
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalScore: number
  totalTime: number
  roles?: Record<string, number>
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  JavaScript: javascript,
  React: react,
  Python: python,
  DataRole: dataRole,
  'AI Maturity Test': aiKnowledge,

  'General Knowledge': generalKnowledge,
}
